import React from "react";
import "./Products.css";
import notifire from "../../../../assets/notifire.png";
import { useTranslation } from "react-i18next";

const Products = () => {
  const { t } = useTranslation();

  return (
    <section className="products">
      <p className="stn name">{t("products.apps")}</p>
      <h2 className="stn desc">{t("products.upgradeExperience")}</h2>
      <div className="product-container">
        <div className="product">
          <div className="product-details">
            <h3>{t("products.notifireTitle")}</h3>
            <p>{t("products.notifireDescription")}</p>
            <div className="product-cta">
              <button
                className="btn info"
                onClick={() => (window.location.href = "/apps/notifire")}
              >
                {t("products.learnMore")}
              </button>
              <button
                className="btn invite"
                onClick={() =>
                  window.open(
                    "https://discord.com/oauth2/authorize?client_id=1257310144895647795",
                    "_blank",
                    "width=450,height=750"
                  )
                }
              >
                {t("products.invite")}
              </button>
            </div>
          </div>
          <div className="product-image">
            <img src={notifire} alt="Notifire" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Products;
