import React from "react";
import { useTranslation } from "react-i18next";
import "./Footer.css";

const currentYear = new Date().getFullYear();

const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <div className="footer-social">
        {/* Add social media icons or links here */}
      </div>
      <div className="footer-container">
        <div>
          <a href="/">&copy; {currentYear} HT-Bots</a>
        </div>
        <div className="footer-legal">
          <a href="/terms">{t("footer.termsOfService")}</a>
          <a href="/privacy">{t("footer.privacyPolicy")}</a>
          <a href="https://discord.gg/Q4gJEVePtv">{t("footer.support")}</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
