import React from "react";
import "./Terms.css";

function TermsOfService() {
  return (
    <div className="tos-container">
      <h1>Terms of Service</h1>
      <h2>Introduction</h2>
      <p>
        Welcome to HT-Bots. These Terms of Service ("TOS") govern your use of
        our Discord bots. By using our bots, you agree to these TOS. If you do
        not agree, do not use the bots.
      </p>
      <h2>Usage Rights</h2>
      <p>
        You must be at least 13 years old to use this bot. The bots requires
        certain permissions to function correctly, including ["Administrator"].
      </p>
      <h2>Prohibited Activities</h2>
      <p>
        You agree not to misuse the bot, use it for illegal activities, or
        attempt to harm the bot or its users.
      </p>
      <h2>Disclaimer of Liability</h2>
      <p>
        We are not liable for any damages or losses arising from the use of the
        bot. Use the bot at your own risk.
      </p>
      <h2>Changes to TOS</h2>
      <p>
        We may change these TOS at any time. Changes will be posted here, and
        continued use of the bot signifies acceptance of the new TOS.
      </p>
      <h2>Termination</h2>
      <p>
        We reserve the right to terminate access to the bot for any reason
        without notice.
      </p>
      <p>Contact us at support@ht-bots.de for any questions or concerns.</p>
    </div>
  );
}

export default TermsOfService;
