import React from "react";
import "./Privacy.css";

function PrivacyPolicy() {
  return (
    <div className="pp-container">
      <h1>Privacy Policy</h1>
      <h2>Introduction</h2>
      <p>
        At HT-Bots, we respect your privacy and are committed to protecting your
        personal data. This Privacy Policy explains how we collect, use, and
        share information about you when you use our Discord bot.
      </p>
      <h2>Data Collection</h2>
      <p>
        We collect the following types of data: Server, Channel & User IDs. This
        information is used to provide customized services and notifications
        within Discord servers & users.
      </p>
      <h2>Data Security</h2>
      <p>
        We take data security seriously. We implement reasonable security
        measures to protect your data from unauthorized access, disclosure,
        alteration, or destruction.
      </p>
      <h2>Data Sharing</h2>
      <p>
        We do not share your data with third parties, except as required by law
        or with your consent.
      </p>
      <h2>User Rights</h2>
      <p>
        You have the right to access, correct, and delete your data. To exercise
        these rights, contact us at support@ht-bots.de.
      </p>
      <h2>Changes to Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. We will notify you
        of any changes by posting the new policy here.
      </p>
      <p>Contact us at support@ht-bots.de for any questions or concerns.</p>
    </div>
  );
}

export default PrivacyPolicy;
