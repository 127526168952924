import React from "react";
import { useTranslation } from "react-i18next";
import "./Hero.css";

export default function Hero() {
  const { t } = useTranslation();

  return (
    <section className="hero">
      <div className="container">
        <h2>{t("hero.title")}</h2>
        <p>{t("hero.description")}</p>
      </div>
    </section>
  );
}
