import React from "react";
import "./NotFound.css";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();

  const redirectToHome = () => {
    window.location.href = "/";
  };

  return (
    <section className="not_found">
      <h1 className="not_found-heading">{t("notFound.notFoundHeading")}</h1>
      <button className="not_found-button" onClick={redirectToHome}>
        {t("notFound.backToHomepage")}
      </button>
    </section>
  );
};

export default NotFound;
