import React from "react";
import { Hero } from "./components";
import { Products } from "./components";

export default function Home() {
  return (
    <div>
      <Hero />
      <Products />
    </div>
  );
}
